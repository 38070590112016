"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AccountInteractor = function () {
  function AccountInteractor(dataService, accountGateway) {
    _classCallCheck(this, AccountInteractor);

    this.dataService = dataService;
    this.accountGateway = accountGateway;
  }

  _createClass(AccountInteractor, [{
    key: "updateDetails",
    value: function updateDetails(name, phone) {
      var person = this.dataService.person;
      person.name = name;
      person.phone = phone;
      this.dataService.person = person;
      return this.accountGateway.updateDetails(name, phone, this.dataService.secretKey);
    }
  }]);

  return AccountInteractor;
}();

exports.default = AccountInteractor;