const WorkspacesList = function ($scope, workspacesInteractor) {
  var $ctrl = this;

  $ctrl.leaveWorkspace = function (workspace) {
    let confirmed = window.confirm('Are you sure that you want to leave this workspace?');

    if (confirmed) {
      workspacesInteractor.leaveWorkspace(workspace.id, function () {
        $ctrl.workspaces.splice($ctrl.workspaces.indexOf(workspace), 1);
        $scope.$digest();
      }, function () {
        window.alert('Oops! We failed to remove you from this workspace.');
      })
    }
  }
};

export default {
  controller: WorkspacesList,
  templateUrl: 'components/workspacesList.html',
  bindings: {
    workspaces: '<'
  }
};