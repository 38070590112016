/* eslint-disable quotes */
export default function () {
  return {
    loggedIn: [ {
      href: "#/questionnaires",
      label: "Questionnaires"
    }, {
      href: "#/workspaces",
      label: "Workspaces"
    }, {
      href: "#/coaches",
      label: "Coaches"
    }, {
      href: "#/profile",
      label: "Profile"
    } ],
    loggedOut: [ {
      href: "#/create_account",
      label: "Create Account"
    }, {
      href: "#/",
      label: "Log In"
    } ]
  }
}