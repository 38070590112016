export default function ($scope, dataService, workspacesInteractor) {
  const $ctrl = this;

  workspacesInteractor.loadWorkspaces(function () {
    console.log(dataService);
    $ctrl.workspaces = dataService.workspaces;
    initMembers();
    $scope.$digest();
  });

  function initMembers() {
    $ctrl.workspaces.forEach(function (workspace) {
      workspace.membersList = workspace.members.map(m => m.name).join(', ');
    })
  }
}
