import routes from './routes'

function OnConfig($locationProvider, $routeProvider) {

    routes().forEach(function (route) {
        $routeProvider.when(
            route.route,
            {
                templateUrl: route.templateUrl,
                controller: route.controller + ' as $ctrl'
            })
    });

    $locationProvider.hashPrefix('');

}

export default OnConfig;
