import { priceFormatter } from '@mojility/coach-core';

const PurchasableQuestionnaires = function ($scope, paymentManager, gateway, dataService) {
  const $ctrl = this;
  $ctrl.isBusy = false;

  $ctrl.$onInit = function () {
    gateway.getPurchasableQuestionnaires(dataService.secretKey, data => {
      $ctrl.questionnaires = data.questionnaires;
      $scope.$digest();
    }, () => {
      window.alert('Oops! We encountered a problem getting a list of purchasable questionnaires')
    });

    paymentManager.setGateway(gateway);
    paymentManager.setPurchaserAuthToken(dataService.secretKey);
  };

  $ctrl.formattedPrice = function (price) {
    return price == 0 ? 'Free':priceFormatter(price);
  };

  $ctrl.purchase = function (questionnaire) {
    $ctrl.isBusy = true;
    setTimeout(() => {
      $ctrl.isBusy = false;
      $scope.$digest();
    }, 1000);
    paymentManager.purchaseSingleUse(questionnaire, () => {
      window.alert('Successfully purchased! Your questionnaire will appear on this page, and you will be emailed a link to it. Once you have finalized your questionnaire your personalized report will be emailed to you.');
      location.reload();
    }, () => {
      window.alert('Oops! We failed to process your order');
      $scope.$digest();
    });
  }
};

export default {
  controller: PurchasableQuestionnaires,
  templateUrl: 'components/purchasableQuestionnaires.html'
};