export default function ($scope, coachesInteractor) {
  const $ctrl = this;

  coachesInteractor.loadCoaches(() => {
    $ctrl.coaches = coachesInteractor.coaches;
    $scope.$digest();
  });

  $ctrl.dischargeCoach = function (coach) {
    let confirm = window.confirm('Are you sure you want to leave this coach? They will have to reinvite you to continue coaching you');

    if (confirm) {
      coachesInteractor.dischargeCoach(coach.id, () => {
        $ctrl.coaches = coachesInteractor.coaches;
        $scope.$apply();
      }, () => console.log('Failed to discharge coach'))
    }
  };

  $ctrl.onInvitationAccepted = function () {
    coachesInteractor.loadCoaches(function () {
      $ctrl.coaches = coachesInteractor.coaches;
      $scope.$digest();
    });
  }
}
