const WORKSPACESKEY ='workspaces';
export default class {

  constructor(gateway, dataService) {
    this.gateway = gateway;
    this.dataService = dataService;
  }

  loadWorkspaces(onSuccess) {
    this.gateway.retrieveWorkspacesData(this.dataService.secretKey, function (data) {
      this.dataService.store(WORKSPACESKEY, data.workspaces);
      console.log('executing callback on successful call in RII');
      onSuccess();
    }.bind(this), function (data) {
      console.log(data);
    });
  }

  leaveWorkspace(workspaceId, onSuccess, onFailure) {
    this.gateway.leaveWorkspace(this.dataService.secretKey, workspaceId, onSuccess, onFailure)
  }

  get workspaces(){
    return this.dataservice.retrieve(WORKSPACESKEY);
  }
}