export default class HttpInteractor {

  constructor() {
    this.token = null;
  }

  delete(url, successBlock, failBlock) {
    this.request('DELETE', url, null, successBlock, failBlock);
  }

  post(url, data, successBlock, failBlock) {
    this.request('POST', url, data, successBlock, failBlock);
  }

  put(url, data, successBlock, failBlock) {
    this.request('PUT', url, data, successBlock, failBlock);
  }

  get(url, successBlock, failBlock) {
    this.request('GET', url, null, successBlock, failBlock);
  }

  request(method, url, data, successBlock, failBlock) {
    this.xhr = new XMLHttpRequest();
    this.xhr.open(method, url, true);
    this.xhr.setRequestHeader('Content-Type', 'application/json');
    this.xhr.setRequestHeader('Accept', 'application/json');
    if (this.token) {
      this.xhr.setRequestHeader('X-Auth-Token', this.token);
    }

    this.xhr.onload = function () {
      var data = JSON.parse(this.response);
      if (this.status === 200) {
        successBlock(data);
      } else {
        failBlock(data);
      }
    };

    this.xhr.onerror = function () {
      failBlock(this.status);
    };

    if (data) {
      this.xhr.send(JSON.stringify(data));
    } else {
      this.xhr.send();
    }
  }

  setSecret(token) {
    this.token = token;
  }

}