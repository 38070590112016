import angular from 'angular';
import 'angular-route';

// angular modules
import onConfig  from './on_config';
import onRun     from './on_run';

// template cache
import './templates';

// controllers
let controllersModule = angular.module('app.controllers', []);

import CoachesController from './angular/controllers/coaches_controller';
controllersModule.controller('CoachesController', CoachesController);

import DashboardController from './angular/controllers/dashboard_controller';
controllersModule.controller('DashboardController', DashboardController);

import HomeController from './angular/controllers/home_controller';
controllersModule.controller('HomeController', HomeController);

import PasswordResetRequestController from './angular/controllers/password_reset_request_controller';
controllersModule.controller('PasswordResetRequestController', PasswordResetRequestController);

import ProfileController from './angular/controllers/profile_controller';
controllersModule.controller('ProfileController', ProfileController);

import QuestionnairesController from './angular/controllers/questionnaires_controller';
controllersModule.controller('QuestionnairesController', QuestionnairesController);

import PasswordResetController from './angular/controllers/password_reset_controller';
controllersModule.controller('PasswordResetController', PasswordResetController);

import SignupFromInvitationEmailController from './angular/controllers/signup_from_invitation_email_controller';
controllersModule.controller('SignupFromInvitationEmailController', SignupFromInvitationEmailController);

import SignupRequestController from './angular/controllers/signup_request_controller';
controllersModule.controller('SignupRequestController', SignupRequestController);

import SignupFinalizeController from './angular/controllers/signup_finalize_controller';
controllersModule.controller('SignupFinalizeController', SignupFinalizeController);

import WorkspacesController from './angular/controllers/workspaces_controller';
controllersModule.controller('WorkspacesController', WorkspacesController);

// services
let servicesModule = angular.module('app.services', []);

import Gateway from './gateways/Gateway';
servicesModule.service('gateway', Gateway);

import { HttpClientFactory, AccountGateway, Flash, CoachBrowserDomGateway, BrowserSessionGateway, BrowserLocationGateway, DataService, LoginInteractor, CoachHttpClientFactory } from '@mojility/coach-core';
servicesModule.service('httpClientFactory', HttpClientFactory);
servicesModule.service('accountGateway', AccountGateway);
servicesModule.service('flash', Flash);
servicesModule.service('coachBrowserDomGateway', CoachBrowserDomGateway);
servicesModule.service('browserSessionGateway', BrowserSessionGateway);
servicesModule.service('browserLocationGateway', BrowserLocationGateway);
servicesModule.service('loginInteractor', LoginInteractor);
servicesModule.service('coachHttpClientFactory', CoachHttpClientFactory);
servicesModule.service('dataService', DataService);

import { Configuration, BrowserDomGateway } from '@mojility/core-es6';
servicesModule.service('configuration', Configuration);
servicesModule.service('browserDomGateway', BrowserDomGateway);

import CoachesInteractor from './interactors/CoachesInteractor';
servicesModule.service('coachesInteractor', CoachesInteractor);

import GetReportInteractor from './interactors/GetReportInteractor';
servicesModule.service('getReportInteractor', GetReportInteractor);

import InvitationsInteractor from './interactors/InvitationsInteractor';
servicesModule.service('invitationsInteractor', InvitationsInteractor);

import PaymentManager from './PaymentManager'; // TODO: Interactor & View Controller?
servicesModule.service('paymentManager', PaymentManager);

import ProfileInteractor from './interactors/ProfileInteractor';
servicesModule.service('profileInteractor', ProfileInteractor);

import QuestionnaireInstanceInteractor from './interactors/QuestionnaireInstanceInteractor';
servicesModule.service('questionnaireInstanceInteractor', QuestionnaireInstanceInteractor);

import SignupFromInvitationInteractor from './interactors/SignupFromInvitationInteractor';
servicesModule.service('signupFromInvitationInteractor', SignupFromInvitationInteractor);

import WorkspacesInteractor from './interactors/WorkspacesInteractor';
servicesModule.service('workspacesInteractor', WorkspacesInteractor);

// components
let componentsModule = angular.module('app.components', []);

import invitations from './angular/components/invitations';
componentsModule.component('invitations', invitations);

import login from './angular/components/login';
componentsModule.component('login', login);

import navbar from './angular/components/navbar';
componentsModule.component('navbar', navbar);

import purchasableQuestionnaires from './angular/components/purchasableQuestionnaires';
componentsModule.component('purchasableQuestionnaires', purchasableQuestionnaires);

import questionnaireInstancesList from './angular/components/questionnaireInstancesList';
componentsModule.component('questionnaireInstancesList', questionnaireInstancesList);

import workspacesList from './angular/components/workspacesList';
componentsModule.component('workspacesList', workspacesList);

// create and bootstrap application
const requires = [ 'ngRoute', 'templates', 'app.controllers', 'app.services', 'app.components' ];

// mount on window for testing
window.app = angular.module('app', requires);
angular.module('app').config(onConfig);
angular.module('app').run(onRun);

angular.module('app').filter('trust', [ '$sce', function ($sce) {
  return function (input) {
    return $sce.trustAsHtml(input);
  }
} ]);

angular.bootstrap(document, [ 'app' ], {
  // strictDi: true
});
