'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (value) {
  if (value === undefined || value === null || value === '') return currency_indicator + '--';
  var price = typeof value === 'number' ? value : parseFloat(value);
  if (isNaN(price)) throw new Error('Price is not a number.');
  return '' + currency_indicator + price.toFixed(2);
};

var currency_indicator = '$';