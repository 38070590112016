
export default class ProfileInteractor {

  constructor(dataService, gateway) {
    this.dataService = dataService;
    this.gateway = gateway;
  }

  updateProfileInformation(profileData, onSuccess, onFailure) {
    let key = this.dataService.secretKey;

    this.gateway.updateClientInformation(key, profileData, data => {
      data.email = this.email;
      this.dataService.setCurrentUser(data, key);
      onSuccess();
    }, () => {
      onFailure();
    })
  }

  get name() {
    return this.dataService.person.name;
  }

  get email() {
    return this.dataService.person.email;
  }

  get phone() {
    return this.dataService.person.phone;
  }
}
