export default function ($scope, dataService, profileInteractor) {
  const $ctrl = this;

  initializeDataFrominteractor();

  $ctrl.updateProfileInformation = function () {
    let profileData = {
      name: $ctrl.name,
      phone: $ctrl.phone
    };

    profileInteractor.updateProfileInformation(profileData, function () {
      initializeDataFrominteractor();
      $scope.$digest();
    }, function () {
      initializeDataFrominteractor();
      $scope.$digest();
    })
  };

  function initializeDataFrominteractor() {
    $ctrl.name = profileInteractor.name;
    $ctrl.phone = profileInteractor.phone;
  }
}
