/* eslint-disable quotes */
export default function () {
  return [ {
    "route": "/",
    "templateUrl": "screens/home.html",
    "controller": "HomeController"
  }, {
    "route": "/dashboard",
    "templateUrl": "screens/dashboard.html",
    "controller": "DashboardController"
  }, {
    "route": "/request_password_reset",
    "templateUrl": "screens/password_reset_request.html",
    "controller": "PasswordResetRequestController"
  }, {
    "route": "/reset_password/:token",
    "templateUrl": "screens/password_reset.html",
    "controller": "PasswordResetController"
  }, {
    "route": "/profile",
    "templateUrl": "screens/profile.html",
    "controller": "ProfileController"
  }, {
    "route": "/workspaces",
    "templateUrl": "screens/workspaces.html",
    "controller": "WorkspacesController"
  }, {
    "route": "/coaches",
    "templateUrl": "screens/coaches.html",
    "controller": "CoachesController"
  }, {
    "route": "/create_account",
    "templateUrl": "screens/signup/request.html",
    "controller": "SignupRequestController"
  }, {
    "route": "/create_account/:token",
    "templateUrl": "screens/signup/finalize.html",
    "controller": "SignupFinalizeController"
  }, {
    "route": "/invitation/:token",
    "templateUrl": "screens/signup_from_invitation_email.html",
    "controller": "SignupFromInvitationEmailController"
  }, {
    "route": "/questionnaires",
    "templateUrl": "screens/questionnaires.html",
    "controller": "QuestionnairesController"
  } ]
}