'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dec, _class;

var _aureliaFramework = require('aurelia-framework');

var _dom_gateway = require('../gateways/browser/dom_gateway');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * In order for this configuration class to work, you need two things stashed in the global
 * Javascript context (ie. window on browsers):
 *
 *   - the environment in which the app is configured to run (ie. development, staging, production)
 *   - a hash containing the serviceUrl for each of the available environments
 *
 * Typically, you'd set up the available environments using BrowserDomGateway:
 * ie.
 *     browserDomGateway.setGlobalValue(Configuration.EnvironmentsKey, {
 *      'development': { serviceUrl: 'http://localhost:3000' },
 *      'staging':     { serviceUrl: 'https://staging.myapp.com' },
 *      'production':  { serviceUrl: 'https://myapp.com' }
 *     });
 *
 * Typically, you'd initialize the environment using a simple piece of Javascript code
 * injected by your build system into the app's index.html:
 * ie.
 *     <script language="javascript">window.APP_ENV='production';</script>
 */

var Configuration = (_dec = (0, _aureliaFramework.inject)(_dom_gateway.BrowserDomGateway), _dec(_class = function () {
  function Configuration(browserDomGateway) {
    _classCallCheck(this, Configuration);

    this.browserDomGateway = browserDomGateway;
  }

  _createClass(Configuration, [{
    key: 'env',
    get: function get() {
      var app_env = this.browserDomGateway.getGlobalValue(Configuration.AppEnvKey);
      var e = typeof app_env === 'undefined' || app_env === '_APP_ENV_' ? 'development' : app_env;
      return e;
    }
  }, {
    key: 'settings',
    get: function get() {
      var environments = this.browserDomGateway.getGlobalValue(Configuration.EnvironmentsKey);
      return environments[this.env];
    }
  }], [{
    key: 'EnvironmentsKey',
    get: function get() {
      return 'Environments';
    }
  }, {
    key: 'AppEnvKey',
    get: function get() {
      return 'APP_ENV';
    }
  }]);

  return Configuration;
}()) || _class);
exports.default = Configuration;
;