import HttpInteractor from '../util/http'
import Settings from '../settings'

export default class Gateway {
  constructor() {
    this.httpInteractor = new HttpInteractor();
  }

  retrieveQuestionnaireInstancesData(secretKey, onSuccess, onFailure) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.get(Settings.serviceUrl + '/api/v1/client/questionnaire_instances', function (data) {
      onSuccess(data);
    }, function (data) {
      onFailure(data);
    })
  }

  retrieveWorkspacesData(secretKey, onSuccess, onFailure) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.get(Settings.serviceUrl + '/api/v1/client/workspaces', function (data) {
      onSuccess(data);
    }, function (data) {
      onFailure(data);
    })
  }

  retrieveCoachesData(secretKey, onSuccess, onFailure) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.get(Settings.serviceUrl + '/api/v1/client/coaches', function (data) {
      onSuccess(data);
    }, function (data) {
      onFailure(data);
    })
  }

  updateClientInformation(secretKey, clientData, onSuccess, onFailure) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.put(Settings.serviceUrl + '/api/v1/client', clientData, function (data) {
      onSuccess(data);
    }, function (data) {
      onFailure(data);
    })
  }

  getInvitations(secretKey, onSuccess, onFailure) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.get(Settings.serviceUrl + '/api/v1/invite/client', function (data) {
      onSuccess(data);
    }, function (data) {
      onFailure(data);
    })
  }

  acceptInvitation(secretKey, id, onSuccess) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.put(Settings.serviceUrl + '/api/v1/invite/accept', {invitation_id: id}, function (data) {
      onSuccess(data);
    }, function () {
    })
  }

  declineInvitation(secretKey, id, onSuccess) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.delete(Settings.serviceUrl + '/api/v1/invite/' + id, function (data) {
      onSuccess(data);
    }, function () {
    })

  }

  dischargeCoach(secretKey, id, onSuccess, onFailure) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.delete(Settings.serviceUrl + '/api/v1/client/person/' + id, function (data) {
      onSuccess(data);
    }, function (data) {
      onFailure(data)
    })
  }

  deleteQuestionnaireInstance(secretKey, id, onSuccess, onFailure) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.delete(Settings.serviceUrl + '/api/v1/client/questionnaire_instance/' + id, function (data) {
      onSuccess(data)
    }, function (data) {
      onFailure(data)
    })
  }

  leaveWorkspace(secretKey, workspaceId, onSuccess, onFailure) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.delete(Settings.serviceUrl + '/api/v1/client/workspaces/' + workspaceId, onSuccess, onFailure)
  }

  getPurchasableQuestionnaires(secretKey, onSuccess, onFailure) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.get(Settings.serviceUrl + '/api/v1/client/purchasable_questionnaires', onSuccess, onFailure)
  }

  purchaseQuestionnaire(secretKey, questionnaireId, stripeToken, onSuccess, onFailure) {
    this.httpInteractor.setSecret(secretKey);
    this.httpInteractor.post(Settings.serviceUrl + '/api/v1/store/purchase_questionnaire', {
      questionnaire_id: questionnaireId,
      stripe_token: stripeToken
    }, onSuccess, onFailure)
  }
}
