"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BrowserSessionGateway = function () {
  function BrowserSessionGateway() {
    _classCallCheck(this, BrowserSessionGateway);
  }

  _createClass(BrowserSessionGateway, [{
    key: "exists",
    value: function exists(key) {
      return sessionStorage[key] !== undefined;
    }
  }, {
    key: "setValue",
    value: function setValue(key, value) {
      sessionStorage[key] = JSON.stringify(value);
      return value;
    }
  }, {
    key: "getValue",
    value: function getValue(key) {
      return JSON.parse(sessionStorage[key]);
    }
  }, {
    key: "deleteValue",
    value: function deleteValue(key) {
      sessionStorage.removeItem(key);
    }
  }, {
    key: "clear",
    value: function clear() {
      sessionStorage.clear();
    }
  }]);

  return BrowserSessionGateway;
}();

exports.default = BrowserSessionGateway;