export default class {
    //Requires Stripe's checkout.js to be loaded remotely in the view

    constructor() {
    }

    setGateway(gateway) {
        this.gateway = gateway;
    }

    setPurchaserAuthToken(key) {
        this.userKey = key;
    }

    purchaseSingleUse(questionnaire, onPurchaseSuccess, onPurchaseFailure) {
        if (questionnaire.price == 0) {
            this.makeApiPurchaseCall(
                questionnaire,
                onPurchaseSuccess,
                onPurchaseFailure,
                null)
        } else {
            this.purchaseUsingStripe(
                questionnaire,
                onPurchaseSuccess,
                onPurchaseFailure)
        }
    }

    purchaseUsingStripe(questionnaire, onPurchaseSuccess, onPurchaseFailure) {
        this.handler = StripeCheckout.configure({
            key: 'pk_test_s3RWNymojOwt59kMs1ljRLcq',
            image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
            locale: 'auto',
            token: function (stripeToken) {
                this.makeApiPurchaseCall(
                    questionnaire,
                    onPurchaseSuccess,
                    onPurchaseFailure,
                    stripeToken.id)
            }.bind(this)
        });

        this.handler.open({
            name: 'ContinuousCoaching',
            description: questionnaire.title,
            amount: questionnaire.price * 100,
            currency: 'CAD'
        });
    }

    makeApiPurchaseCall(questionnaire, onPurchaseSuccess, onPurchaseFailure, stripeTokenId) {
        this.createOverlay();
        this.gateway.purchaseQuestionnaire(
            this.userKey,
            questionnaire.id,
            stripeTokenId,
            function (data) {
                this.removeOverlay();
                onPurchaseSuccess(data);
            }.bind(this),
            function (data) {
                this.removeOverlay();
                onPurchaseFailure(data);
            }.bind(this));
    }

    createOverlay() {
        let overlay = document.createElement('div');
        overlay.setAttribute('id', 'processingPurchaseOverlay');

        let div = document.createElement('div');
        let header = document.createElement('h1');
        header.appendChild(document.createTextNode('Processing your order...'));

        overlay.appendChild(div);
        overlay.appendChild(header);

        document.body.appendChild(overlay);
    }

    removeOverlay() {
        document.getElementById('processingPurchaseOverlay').remove();
    }
}