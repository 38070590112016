'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpClientFactory = exports.Failure = exports.HttpVerbs = undefined;

var _dec, _class;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _aureliaFramework = require('aurelia-framework');

var _configuration = require('./configuration');

var _configuration2 = _interopRequireDefault(_configuration);

var _aureliaFetchClient = require('aurelia-fetch-client');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var HttpVerbs = exports.HttpVerbs = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

var Failure = exports.Failure = function () {
  function Failure() {
    _classCallCheck(this, Failure);
  }

  _createClass(Failure, null, [{
    key: 'reject',
    value: function reject(severity) {
      var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'An error occured talking to the server.';

      return Promise.reject({
        severity: severity,
        message: message
      });
    }
  }]);

  return Failure;
}();

var HttpClientFactory = exports.HttpClientFactory = (_dec = (0, _aureliaFramework.inject)(_configuration2.default), _dec(_class = function () {
  function HttpClientFactory(configuration) {
    _classCallCheck(this, HttpClientFactory);

    this.config = configuration;
  }

  _createClass(HttpClientFactory, [{
    key: 'getPathPrefix',
    value: function getPathPrefix() {
      return '/';
    }
  }, {
    key: 'getBaseUrl',
    value: function getBaseUrl() {
      return this.config.settings.serviceUrl + this.getPathPrefix();
    }
  }, {
    key: 'createClient',
    value: function createClient() {
      var _this = this;

      return new _aureliaFetchClient.HttpClient().configure(function (config) {
        config.withBaseUrl(_this.getBaseUrl()).withDefaults({
          headers: _this.buildBaseHeaders()
        });
      });
    }
  }, {
    key: 'buildBaseHeaders',
    value: function buildBaseHeaders() {
      return {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };
    }
  }, {
    key: 'createAuthenticatedClient',
    value: function createAuthenticatedClient(secretKey) {
      return this.createClient().configure(function (config) {
        config.withDefaults({
          headers: {
            'X-Auth-Token': secretKey
          }
        });
      });
    }
  }]);

  return HttpClientFactory;
}()) || _class);