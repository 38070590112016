'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dec, _class;

var _aureliaFramework = require('aurelia-framework');

var _account_gateway = require('../gateways/account_gateway');

var _account_gateway2 = _interopRequireDefault(_account_gateway);

var _data_service = require('../services/data_service');

var _data_service2 = _interopRequireDefault(_data_service);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LoginInteractor = (_dec = (0, _aureliaFramework.inject)(_data_service2.default, _account_gateway2.default), _dec(_class = function () {
  function LoginInteractor(dataService, accountGateway) {
    _classCallCheck(this, LoginInteractor);

    this.dataService = dataService;
    this.gateway = accountGateway;
  }

  _createClass(LoginInteractor, [{
    key: 'isLoggedIn',
    value: function isLoggedIn() {
      return this.dataService.isLoggedIn();
    }
  }, {
    key: 'authenticate',
    value: function authenticate(email, password) {
      var _this = this;

      return this.gateway.authenticate(email, password).then(function (data) {
        _this.dataService.setCurrentUser(data.person, data.secret_key);
        return data;
      });
    }
  }, {
    key: 'invalidate',
    value: function invalidate() {
      this.dataService.clear();
    }
  }, {
    key: 'email',
    get: function get() {
      return this.dataService.person.email;
    }
  }, {
    key: 'phone',
    get: function get() {
      return this.dataService.person.phone;
    }
  }, {
    key: 'name',
    get: function get() {
      return this.dataService.person.name;
    }
  }]);

  return LoginInteractor;
}()) || _class);
exports.default = LoginInteractor;