'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dec, _class;

var _aureliaFramework = require('aurelia-framework');

var _aureliaFetchClient = require('aurelia-fetch-client');

var _coach_http_client_factory = require('../util/coach_http_client_factory');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CoachGateway = (_dec = (0, _aureliaFramework.inject)(_coach_http_client_factory.CoachHttpClientFactory), _dec(_class = function () {
  function CoachGateway(coachHttpClientFactory) {
    _classCallCheck(this, CoachGateway);

    this.http = coachHttpClientFactory;
  }

  _createClass(CoachGateway, [{
    key: 'getVideoToken',
    value: function getVideoToken(secretKey) {
      return this.http.createAuthenticatedClient(secretKey).fetch('conf/token').then(function (response) {
        return response.json();
      });
    }
  }, {
    key: 'getWorkspaces',
    value: function getWorkspaces(secretKey) {
      return this.http.createAuthenticatedClient(secretKey).fetch('client/workspaces').then(function (response) {
        return response.json();
      });
    }
  }]);

  return CoachGateway;
}()) || _class);
exports.default = CoachGateway;