'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _aureliaFramework = require('aurelia-framework');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var BrowserDomGateway = function () {
  function BrowserDomGateway() {
    _classCallCheck(this, BrowserDomGateway);
  }

  _createClass(BrowserDomGateway, [{
    key: 'appendMetaPropertyToDom',
    value: function appendMetaPropertyToDom(property, content) {
      var el = this.createMetaElement(property, content);
      this.appendToHead(el);
    }
  }, {
    key: 'appendToHead',
    value: function appendToHead(el) {
      this.getHeadElement().appendChild(el);
    }
  }, {
    key: 'getHeadElement',
    value: function getHeadElement() {
      return document.getElementsByTagName('head')[0];
    }
  }, {
    key: 'createLinkElement',
    value: function createLinkElement(stylesheetUrl) {
      var el = document.createElement('link');
      el.setAttribute('rel', 'stylesheet');
      el.setAttribute('href', stylesheetUrl);
      return el;
    }
  }, {
    key: 'createMetaElement',
    value: function createMetaElement(property, content) {
      var el = document.createElement('meta');
      el.setAttribute('property', property);
      el.setAttribute('content', content);
      return el;
    }
  }, {
    key: 'setGlobalValue',
    value: function setGlobalValue(key, value) {
      window[key] = value;
    }
  }, {
    key: 'getGlobalValue',
    value: function getGlobalValue(key) {
      return window[key];
    }
  }]);

  return BrowserDomGateway;
}();

exports.default = BrowserDomGateway;