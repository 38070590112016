const Invitations = function ($scope, invitationsInteractor) {
  const $ctrl = this;

  $ctrl.$onInit = () => {
    invitationsInteractor.loadInvitations(invitations => {
      $ctrl.invitations = $ctrl.filterAcceptedInvitations(invitations);
      $scope.$digest();
    }, error => {
      console.log(error);
    })
  };

  $ctrl.acceptInvitation = invitation => {
    invitationsInteractor.accept(invitation.id, () => {
      $ctrl.invitations.splice($ctrl.invitations.indexOf(invitation), 1);
      $ctrl.onAccept();
      $scope.$digest();
    })
  };

  $ctrl.declineInvitation = invitation => {
    invitationsInteractor.decline(invitation.id, () => {
      $ctrl.invitations.splice($ctrl.invitations.indexOf(invitation), 1);
      $scope.$digest();
    })
  };

  $ctrl.filterAcceptedInvitations = invitations => {
      return invitations.filter(invitation => {
        return !invitation.isAccepted
      });
  };
};

export default {
  controller: Invitations,
  templateUrl: 'components/invitations.html',
  bindings: {
    onAccept: '&'
  }
};