const QUESTIONNAIRESKEY ='questionnaires';
class QuestionnaireInstanceInteractor {

  constructor(gateway, dataService) {
    this.gateway = gateway;
    this.dataService = dataService;
  }

  loadQuestionnaireInstances(onSuccess) {
    this.gateway.retrieveQuestionnaireInstancesData(this.dataService.secretKey, function (data) {
      this.dataService.store(QUESTIONNAIRESKEY, data.questionnaire_instances);
      onSuccess();
    }.bind(this), function (data) {
      console.log(data);
    });
  }

  deleteInstance(instanceId, onSuccess, onFailure) {
    this.gateway.deleteQuestionnaireInstance(this.dataService.secretKey, instanceId, onSuccess, onFailure);
  }

  get questionnaires(){
    return this.dataService.retrieve(QUESTIONNAIRESKEY);
  }
}

export default QuestionnaireInstanceInteractor