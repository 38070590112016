const COACHKEY = 'coaches';

class CoachesInteractor {

  constructor(gateway, dataService) {
    this.gateway = gateway;
    this.dataService = dataService;
  }

  loadCoaches(onSuccess) {
  this.gateway.retrieveCoachesData(this.dataService.secretKey, function (data) {
    this.dataService.store(COACHKEY, data.coaches);
    console.log('executing callback on successful call in RII');
    onSuccess();
  }.bind(this), function (data) {
    console.log(data);
  });
}

  dischargeCoach(id, onSuccess, onFailure) {
    this.gateway.dischargeCoach(this.dataService.secretKey, id, function (data) {
      this.loadCoaches(function () {
        onSuccess(data)
      })
    }.bind(this), function (data) {
      onFailure(data);
    })
  }

  get coaches () {
    return this.dataService.retrieve(COACHKEY);
  }
}

export default CoachesInteractor