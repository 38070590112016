import { Status } from '../../util/enumerations';

export default function ($scope, $location, flash, accountGateway) {
  const $ctrl = this;
  $ctrl.actionStatus = Status.READY;

  $ctrl.submit = function (email) {
    $ctrl.actionStatus = Status.PROCESSING;
    $ctrl.actionStatusMessage = 'Processing';

    accountGateway.requestAccountCreation(email)
      .then(() => {
        $scope.$applyAsync(() => {
          flash.message = `An email containing instructions on how to complete your registration has been sent to ${email}.`;
          $location.path('/');
        });
      })
      .catch(err => {
        $ctrl.actionStatus = Status.FAILED;
        $ctrl.actionStatusMessage = `Failed to start registration: ${err.message}`;
        $scope.$digest();
      });

    $ctrl.email = '';
  };

  $ctrl.submitIsDisabled = function () {
    return $ctrl.isBusy() || !$ctrl.email;
  };

  $ctrl.isBusy = function () {
    return $ctrl.actionStatus !== Status.READY;
  }
};
