'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dec, _class;

var _aureliaFramework = require('aurelia-framework');

var _enumerations = require('../util/enumerations');

var _coreEs = require('@mojility/core-es6');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DataService = (_dec = (0, _aureliaFramework.inject)(_coreEs.BrowserSessionGateway), _dec(_class = function () {
  function DataService(browserSessionGateway) {
    _classCallCheck(this, DataService);

    this.sessionBackedKeys = [_enumerations.SessionKey.Person, _enumerations.SessionKey.Workspaces, _enumerations.SessionKey.SecretKey];

    this.session = browserSessionGateway;
    this.data = new Map();
  }

  _createClass(DataService, [{
    key: 'isLoggedIn',
    value: function isLoggedIn() {
      return !!this.person;
    }
  }, {
    key: 'setCurrentUser',
    value: function setCurrentUser(person, secretKey) {
      this.person = this.store(_enumerations.SessionKey.Person, person);
      this.secretKey = this.store(_enumerations.SessionKey.SecretKey, secretKey);
    }

    // Generic functions

  }, {
    key: 'retrieve',
    value: function retrieve(key) {
      if (!this.data.has(key)) {
        if (this.sessionBackedKeys.includes(key) && this.session.exists(key)) {
          this.data.set(key, this.session.getValue(key));
        }
      }
      return this.data.get(key);
    }
  }, {
    key: 'store',
    value: function store(key, value) {
      var _this = this;

      this.data.set(key, value);
      this.ifBackedDo(key, function () {
        return _this.session.setValue(key, value);
      });
      return value;
    }
  }, {
    key: 'delete',
    value: function _delete(key) {
      var _this2 = this;

      this.data.delete(key);
      this.ifBackedDo(key, function () {
        return _this2.session.deleteValue(key);
      });
    }
  }, {
    key: 'ifBackedDo',
    value: function ifBackedDo(key, func) {
      if (this.sessionBackedKeys.includes(key)) func();
    }
  }, {
    key: 'clear',
    value: function clear() {
      this.data = new Map();
      this.session.clear();
    }
  }, {
    key: 'person',
    get: function get() {
      return this.retrieve(_enumerations.SessionKey.Person);
    },
    set: function set(person) {
      this.store(_enumerations.SessionKey.Person, person);
    }
  }, {
    key: 'secretKey',
    get: function get() {
      return this.retrieve(_enumerations.SessionKey.SecretKey);
    },
    set: function set(secretKey) {
      this.store(_enumerations.SessionKey.SecretKey, secretKey);
    }
  }, {
    key: 'workspaces',
    get: function get() {
      return this.retrieve(_enumerations.SessionKey.Workspaces);
    },
    set: function set(workspaces) {
      return this.store(_enumerations.SessionKey.Workspaces, workspaces);
    }
  }]);

  return DataService;
}()) || _class);
exports.default = DataService;