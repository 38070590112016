import { Status } from '../../util/enumerations';

export default function ($scope, $routeParams, $location, flash, accountGateway) {
  const $ctrl = this;
  $ctrl.actionStatus = Status.READY;
  $ctrl.token = $routeParams.token;

  $ctrl.resetPassword = function () {
    $ctrl.actionStatus = Status.PROCESSING;
    $ctrl.actionStatusMessage = 'Processing';

    accountGateway.resetPassword($ctrl.token, $ctrl.password)
      .then(() => {
        $scope.$applyAsync(() => {
          flash.message = 'Password reset successful! You may now log in with your new password';
          $location.path('/');
        });
      })
      .catch(err => {
        $ctrl.actionStatus = Status.FAILED;
        $ctrl.actionStatusMessage = 'Failed to reset your password.'
        $scope.$digest();
      });
  };

  $ctrl.submitIsDisabled = function () {
    return $ctrl.isBusy() || !$ctrl.password || $ctrl.password.length < 5 && $ctrl.password !== $ctrl.confirmation;
  };

  $ctrl.isBusy = function () {
    return $ctrl.actionStatus !== Status.READY;
  };
}
