import navbarLinks from '../../navbar_links';

const Navbar = function ($location, dataService, loginInteractor) {
  const $ctrl = this;

  $ctrl.$onInit = function () {
    $ctrl.links = navbarLinks();
  };

  // TODO: Diverged :(
  $ctrl.logout = function () {
    loginInteractor.invalidate();
    $location.path('/');
  };

  $ctrl.userDidClickLogo = function() {
    $location.path($ctrl.isLoggedIn() ? 'dashboard':'');
  };

  $ctrl.isLoggedIn = function () {
    return dataService.secretKey != null;
  };
};

export default {
  controller: Navbar,
  templateUrl: 'components/navbar.html'
};
