'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dec, _class;

var _aureliaFramework = require('aurelia-framework');

var _coach_gateway = require('../gateways/coach_gateway');

var _coach_gateway2 = _interopRequireDefault(_coach_gateway);

var _data_service = require('../services/data_service');

var _data_service2 = _interopRequireDefault(_data_service);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var WorkspaceInteractor = (_dec = (0, _aureliaFramework.inject)(_data_service2.default, _coach_gateway2.default), _dec(_class = function () {
  function WorkspaceInteractor(dataService, coachGateway) {
    _classCallCheck(this, WorkspaceInteractor);

    this.dataService = dataService;
    this.gateway = coachGateway;
  }

  _createClass(WorkspaceInteractor, [{
    key: 'getWorkspaces',
    value: function getWorkspaces() {
      var _this = this;

      return this.gateway.getWorkspaces(this.dataService.secretKey).then(function (data) {
        _this.dataService.workspaces = data.workspaces;
        return data.workspaces;
      });
    }
  }]);

  return WorkspaceInteractor;
}()) || _class);
exports.default = WorkspaceInteractor;