'use strict';

export default {

    development: {
        serviceUrl: 'http://localhost:3000'
    },

    uat: {
        serviceUrl: 'https://api-uat.continuouscoaching.ca'
    },

    production: {
        serviceUrl: 'https://api.continuouscoaching.ca'
    }
};
