export default class {
  constructor(accountGateway) {
    this.accountGateway = accountGateway
  }

  createAccount(token, name, phone, email, password) {
    return this.accountGateway.createAccountWithInvitation(token, {
      name: name,
      phone: phone,
      email: email,
      password: password
    })
  }

  getInvitation(token) {
    return this.accountGateway.getInvitation(token);
  }
}