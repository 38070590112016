import { Severity } from '@mojility/coach-core';
import { Status } from '../../util/enumerations';

export default function ($routeParams, $scope, $location, flash, signupFromInvitationInteractor) {
  const $ctrl = this;
  $ctrl.actionStatus = Status.READY;

  signupFromInvitationInteractor.getInvitation($routeParams.token).then(response => {
    $ctrl.defaultInviteeEmail = response.email;
    $ctrl.inviteeEmail = $ctrl.defaultInviteeEmail;
    $ctrl.inviterEmail = response.inviter.email;
    $ctrl.inviterName = response.inviter.name;
    $scope.$digest();
  }).catch(error => $ctrl.handleError(error));

  $ctrl.submit = function () {
    signupFromInvitationInteractor.createAccount($routeParams.token, $ctrl.name, $ctrl.phone, $ctrl.inviteeEmail, $ctrl.password, $ctrl.confirmation)
      .then(() => {
        $scope.$applyAsync(() => {
          window.alert('Account created successfully! ' + 'Log in with the email and password you just ' + 'set to accept your invitations and start getting coached!');
          $location.path('/');
        })
      })
      .catch(error => $ctrl.handleError(error))
  };

  $ctrl.handleError = function (error) {
    $scope.$applyAsync(() => {
      window.alert(error.message);
      if (error.severity === Severity.ERROR) $location.path('/');
    })
  };

  $ctrl.submitIsDisabled = function () {
    return $ctrl.actionStatus === 'processing' || $ctrl.inviteeEmail.length < 3 || $ctrl.name.length < 2 || $ctrl.password.length < 5 || $ctrl.password != $ctrl.confirmation;
  }
};
