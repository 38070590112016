import { Status } from '../../util/enumerations';

export default function ($scope, $location, flash, accountGateway) {
  const $ctrl = this;
  $ctrl.actionStatus = Status.READY;

  $ctrl.requestResetEmail = function () {
    $ctrl.actionStatus = Status.PROCESSING;
    $ctrl.actionStatusMessage = 'Processing';

    accountGateway.requestPasswordReset($ctrl.email)
      .then(() => {
        $scope.$applyAsync(() => {
          flash.message = 'You have been sent an email containing information required to reset your password.';
          $location.path('/');
        });
      })
      .catch(err => {
        $ctrl.actionStatus = Status.FAILED;
        $ctrl.actionStatusMessage = 'Failed to request a password reset for this email address.';
        $scope.$digest();
      });

    $ctrl.email = '';
  };

  $ctrl.submitIsDisabled = function () {
    return $ctrl.isBusy() || !$ctrl.email
  };

  $ctrl.isBusy = function () {
    return $ctrl.actionStatus !== Status.READY;
  };
}