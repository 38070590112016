'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dec, _class;

var _aureliaFramework = require('aurelia-framework');

var _aureliaFetchClient = require('aurelia-fetch-client');

var _coreEs = require('@mojility/core-es6');

var _coach_http_client_factory = require('../util/coach_http_client_factory');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var AccountGateway = (_dec = (0, _aureliaFramework.inject)(_coach_http_client_factory.CoachHttpClientFactory), _dec(_class = function () {
  function AccountGateway(coachHttpClientFactory) {
    _classCallCheck(this, AccountGateway);

    this.http = coachHttpClientFactory;
  }

  _createClass(AccountGateway, [{
    key: 'authenticate',
    value: function authenticate(email, password) {
      return this.http.createClient().fetch('authenticate', {
        method: _coreEs.HttpVerbs.POST,
        body: (0, _aureliaFetchClient.json)({
          email: email,
          password: password
        })
      }).then(function (response) {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return _coreEs.Failure.reject(_coreEs.Severity.WARNING, 'Invalid email or password.');
          default:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR);
        }
      });
    }
  }, {
    key: 'requestAccountCreation',
    value: function requestAccountCreation(email) {
      return this.http.createClient().fetch('account/creation', {
        method: _coreEs.HttpVerbs.POST,
        body: (0, _aureliaFetchClient.json)({
          email: email
        })
      }).then(function (response) {
        switch (response.status) {
          case 200:
            return response.json();
          case 400:
            return _coreEs.Failure.reject(_coreEs.Severity.WARNING, 'That email address is already associated with an account.');
          default:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR);
        }
      });
    }
  }, {
    key: 'checkCreationToken',
    value: function checkCreationToken(token) {
      return this.http.createClient().fetch('account/creation/' + token, {
        method: _coreEs.HttpVerbs.GET
      }).then(function (response) {
        switch (response.status) {
          case 200:
            return response.json();
          case 404:
            return _coreEs.Failure.reject(_coreEs.Severity.WARNING, 'Unable to validate your token.');
          default:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR);
        }
      });
    }
  }, {
    key: 'completeAccountCreation',
    value: function completeAccountCreation(data) {
      return this.http.createClient().fetch('account/creation/' + data.token, {
        method: _coreEs.HttpVerbs.PUT,
        body: (0, _aureliaFetchClient.json)({
          name: data.name,
          phone: data.phone,
          password: data.password
        })
      }).then(function (response) {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR);
        }
      });
    }
  }, {
    key: 'getInvitation',
    value: function getInvitation(token) {
      return this.http.createClient().fetch('account/invitation/' + token, {
        method: _coreEs.HttpVerbs.GET
      }).then(function (response) {
        switch (response.status) {
          case 200:
            return response.json();
          case 400:
            return _coreEs.Failure.reject(_coreEs.Severity.WARNING, 'An account has already been created for this invitation. Please log in to accept or decline this invitation.');
          case 404:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR, 'This invitation does not exist');
          default:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR);
        }
      });
    }
  }, {
    key: 'createAccountWithInvitation',
    value: function createAccountWithInvitation(token, data) {
      data.token = token;
      data.password_confirmation = data.password;

      return this.http.createClient().fetch('account/invitation', {
        method: _coreEs.HttpVerbs.POST,
        body: (0, _aureliaFetchClient.json)(data)
      }).then(function (response) {
        switch (response.status) {
          case 200:
            return response.json();
          case 400:
            return _coreEs.Failure.reject(_coreEs.Severity.WARNING, 'Something is wrong with your information');
          case 404:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR, 'This invitation does not exist');
          default:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR);
        }
      });
    }
  }, {
    key: 'requestPasswordReset',
    value: function requestPasswordReset(email) {
      return this.http.createClient().fetch('account/password/request_reset', {
        method: _coreEs.HttpVerbs.POST,
        body: (0, _aureliaFetchClient.json)({ email: email })
      }).then(function (response) {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR);
        }
      });
    }
  }, {
    key: 'checkResetToken',
    value: function checkResetToken(token) {
      return this.http.createClient().fetch('reset_password/' + token, {
        method: _coreEs.HttpVerbs.GET
      }).then(function (response) {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR);
        }
      });
    }
  }, {
    key: 'resetPassword',
    value: function resetPassword(token, password) {
      return this.http.createClient().fetch('reset_password/' + token, {
        method: _coreEs.HttpVerbs.PUT,
        body: (0, _aureliaFetchClient.json)({
          password: password
        })
      }).then(function (response) {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR);
        }
      });
    }
  }, {
    key: 'updateDetails',
    value: function updateDetails(name, phone, secretKey) {
      return this.http.createAuthenticatedClient(secretKey).fetch('coach/details', {
        method: _coreEs.HttpVerbs.PUT,
        body: (0, _aureliaFetchClient.json)({
          details: {
            name: name,
            phone: phone
          }
        })
      }).then(function (response) {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            return _coreEs.Failure.reject(_coreEs.Severity.ERROR);
        }
      });
    }
  }]);

  return AccountGateway;
}()) || _class);
exports.default = AccountGateway;