'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventsService = exports.Event = exports.EventSeverityLabels = exports.EventSeverity = exports.EventChannel = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dec, _class;

var _aureliaFramework = require('aurelia-framework');

var _aureliaEventAggregator = require('aurelia-event-aggregator');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var EventChannel = exports.EventChannel = {
  System: 'system'
};

var EventSeverity = exports.EventSeverity = {
  Alert: 0,
  Critical: 1,
  Error: 2,
  Warning: 3,
  Notice: 4,
  Informational: 5,
  Debug: 6
};

var EventSeverityLabels = exports.EventSeverityLabels = ['alert', 'crit', 'error', 'warning', 'notice', 'info', 'debug'];

var Event = exports.Event = function Event(type, body) {
  var severity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : EventSeverity.Informational;

  _classCallCheck(this, Event);

  this.timeStamp = new Date();
  this.type = type;
  this.body = body;
  this.severity = severity;
};

var EventsService = exports.EventsService = (_dec = (0, _aureliaFramework.inject)(_aureliaEventAggregator.EventAggregator), _dec(_class = function () {
  function EventsService(eventAggregator) {
    _classCallCheck(this, EventsService);

    this.events = [];

    this.eventAggregator = eventAggregator;
  }

  _createClass(EventsService, [{
    key: 'eventsFor',
    value: function eventsFor(channel) {
      var minimumSeverity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : EventSeverity.Informational;

      return this.events.filter(function (e) {
        return e.type === channel && e.severity <= minimumSeverity;
      });
    }
  }, {
    key: 'publish',
    value: function publish(event) {
      this.events.push(event);
      this.eventAggregator.publish(event.type, event);
    }
  }, {
    key: 'publishSystemMessage',
    value: function publishSystemMessage(text) {
      var severity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : EventSeverity.Informational;

      this.publish(new Event(EventChannel.System, text, severity));
    }
  }, {
    key: 'publishSystemError',
    value: function publishSystemError(text) {
      this.publish(new Event(EventChannel.System, text, EventSeverity.Error));
    }
  }, {
    key: 'subscribe',
    value: function subscribe(channel, callback) {
      this.eventAggregator.subscribe(channel, callback);
    }
  }, {
    key: 'acknowledge',
    value: function acknowledge(event) {
      var index = this.events.indexOf(event);
      if (index >= 0) this.events.splice(index, 1);
    }
  }]);

  return EventsService;
}()) || _class);