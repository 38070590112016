import {Status} from '../../util/enumerations';

const Login = function ($scope, $location, loginInteractor) {
  const $ctrl = this;

  $ctrl.$onInit = () => {
    $ctrl.username = '';
    $ctrl.password = '';
  };

  $ctrl.isLogInDisabled = function () {
    return($ctrl.username === '' || $ctrl.password === '')
  };

  $ctrl.submit = function () {
    $ctrl.actionStatus = Status.PROCESSING;
    loginInteractor.authenticate($ctrl.username.toLowerCase(), $ctrl.password)
      .then(() => {
        $location.path('/dashboard');
        $scope.$apply();
      }, error => {
        $ctrl.actionStatus = Status.FAILED;
        $ctrl.errorMessage = error.message;
        $scope.$digest();
      });
  };

  $ctrl.actionHasFailed = function () {
    return $ctrl.actionStatus === Status.FAILED;
  };

  $ctrl.actionIsProcessing = function () {
    return $ctrl.actionStatus === Status.PROCESSING;
  };
};

export default {
  controller: Login,
  templateUrl: 'components/login.html'
};