import { Status } from '../../util/enumerations';

export default function ($routeParams, $location, $scope, flash, accountGateway) {
  const $ctrl = this;
  $ctrl.actionStatus = Status.READY;

  accountGateway.checkCreationToken($routeParams.token)
    .catch(err => {
      $scope.$applyAsync(() => {
        window.alert('There is no account creation request associated with this link. Maybe you created your account already, or need to make another request');
        $location.path('/');
      })
    });

  $ctrl.submit = function () {
    $ctrl.actionStatus = Status.PROCESSING;
    $ctrl.actionStatusMessage = 'Processing';

    accountGateway.completeAccountCreation({
      token: $routeParams.token,
      name: $ctrl.name,
      phone: $ctrl.phone,
      password: $ctrl.password
    })
      .then(() => {
        $scope.$applyAsync(() => {
          window.alert('Account created successfully! Log in with the email and password you just set to accept your invitations and start getting coached!');
          $location.path('/');
        })
      })
      .catch(err => {
        $ctrl.actionStatus = Status.FAILED;
        $ctrl.actionStatusMessage = `Failed to complete account creation: ${err.message}`;
        $scope.$digest();
      })
  };

  $ctrl.submitIsDisabled = function () {
    return $ctrl.actionStatus === Status.PROCESSING || $ctrl.password !== $ctrl.confirmation || $ctrl.password.length < 5 || $ctrl.name === '';
  };
};
