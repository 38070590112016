
export default class {
  constructor(gateway, dataService) {
    this.gateway = gateway;
    this.dataService = dataService;
  }

  loadInvitations(onSuccess, onFailure) {
    this.gateway.getInvitations(this.dataService.secretKey, function (data) {
      let invitations = [];
      data.invitations.forEach(function (invitation) {
        invitations.push({
          coach: invitation.coach,
          date: new Date(invitation.created_at).toLocaleString(),
          id: invitation.id,
          isAccepted: invitation.is_accepted
        })
      });

      onSuccess(invitations);
    }, function () {
      onFailure();
    })
  }

  accept(invitationId, onSuccess) {
    this.gateway.acceptInvitation(this.dataService.secretKey, invitationId, onSuccess)
  }

  decline(invitationId, onSuccess) {
    this.gateway.declineInvitation(this.dataService.secretKey, invitationId, onSuccess)
  }
}