'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _dec, _desc, _value, _class;

var _aureliaFramework = require('aurelia-framework');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var DefaultPersistence = 5000; // in Milliseconds

var Flash = (_dec = (0, _aureliaFramework.computedFrom)('_message'), (_class = function () {
  function Flash() {
    _classCallCheck(this, Flash);

    this._message = null;
    this._timestamp = null;
  }

  _createClass(Flash, [{
    key: 'message',
    set: function set(msg) {
      this._message = msg;
      this._timestamp = Date.now();
    },
    get: function get() {
      var msg = this._message;
      if (Date.now() - this._timestamp > DefaultPersistence) this._message = null;
      return msg;
    }
  }]);

  return Flash;
}(), (_applyDecoratedDescriptor(_class.prototype, 'message', [_dec], Object.getOwnPropertyDescriptor(_class.prototype, 'message'), _class.prototype)), _class));
exports.default = Flash;