
const QuestionnaireInstancesList = function ($scope, dataService, questionnaireInstanceInteractor, getReportInteractor) {
  const $ctrl = this;

  $ctrl.$onChanges = function () {
    if ($ctrl.questionnaireInstances) {
      $ctrl.dateAsc = false;
      $ctrl.titleFilter = '';
      $ctrl.dateFilter = '';
      $ctrl.senderFilter = '';
      $ctrl.sortByDate();
    }
  };

  $ctrl.parseDate = function (instance) {
    return new Date(instance.created_at).toLocaleString();
  };

  $ctrl.parseSender = function (instance) {
    if (instance.sender) {
      return instance.sender.name
    } else {
      return 'Purchased from ' + instance.coach.name
    }
  };

  $ctrl.deleteInstance = function (instance) {
    console.log(instance);
    let confirmed = window.confirm('Are you sure that you want to delete this questionnaire opportunity?');

    if (confirmed) {
      questionnaireInstanceInteractor.deleteInstance(instance.id, function () {
        $ctrl.questionnaireInstances.splice($ctrl.questionnaireInstances.indexOf(instance), 1);
        $scope.$digest();
      }, function () {
        window.alert('Oops! We failed to delete this questionnaire request');
      })
    }
  };

  $ctrl.getReportUrlFor = function (instance) {
    return getReportInteractor.getReportUrlFor(instance, dataService.secretKey);
  };

  $ctrl.canGetReport = function (instance) {
    return instance.is_open == false && instance.coachee_can_get_report
  };

  $ctrl.sortByDate = function () {
    $ctrl.dateAsc = !$ctrl.dateAsc;

    $ctrl.questionnaireInstances.sort((a, b) => {
      if (a.created_at < b.created_at) {
        if ($ctrl.dateAsc) {
          return 1;
        } else {
          return -1;
        }
      }
      if (a.created_at > b.created_at) {
        if ($ctrl.dateAsc) {
          return -1;
        } else {
          return 1;
        }
      }
      return 0;
    });
  };

  $ctrl.sortByName = function () {
    $ctrl.nameAsc = !$ctrl.nameAsc;

    $ctrl.questionnaireInstances.sort((a, b) => {
      let nameA = a.questionnaire_title.toUpperCase();
      let nameB = b.questionnaire_title.toUpperCase();
      if (nameA < nameB) {
        if ($ctrl.nameAsc) {
          return -1;
        } else {
          return 1;
        }
      }
      if (nameA > nameB) {
        if ($ctrl.nameAsc) {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    })
  };

  $ctrl.sortBySender = function () {
    $ctrl.senderAsc = !$ctrl.senderAsc;

    $ctrl.questionnaireInstances.sort((a, b) => {
      let senderA = a.sender.name.toUpperCase();
      let senderB = b.sender.name.toUpperCase();
      if (senderA < senderB) {
        if ($ctrl.senderAsc) {
          return -1;
        } else {
          return 1;
        }
      }
      if (senderA > senderB) {
        if ($ctrl.senderAsc) {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    })
  };

  $ctrl.updateFilters = function () {
    updateTitleFilter();
    updateDateFilter();
    updateSenderFilter();

    function updateTitleFilter() {
      if ($ctrl.titleFilter.length > 0) {
        $ctrl.titleRegExp = new RegExp($ctrl.titleFilter.toUpperCase());
      } else {
        $ctrl.titleRegExp = null;
      }
    }

    function updateDateFilter() {
      if ($ctrl.dateFilter.length > 0) {
        $ctrl.dateRegExp = new RegExp($ctrl.dateFilter);
      } else {
        $ctrl.dateRegExp = null;
      }
    }

    function updateSenderFilter() {
      if ($ctrl.senderFilter.length > 0) {
        $ctrl.senderRegExp = new RegExp($ctrl.senderFilter.toUpperCase());
      } else {
        $ctrl.senderRegExp = null;
      }
    }
  };

  $ctrl.filteredInstances = function () {
    return $ctrl.questionnaireInstances.filter(instance => $ctrl.filtersPermitInstance(instance))
  };

  $ctrl.filtersPermitInstance = function (instance) {
    if ($ctrl.titleRegExp) {
      if (!$ctrl.titleRegExp.test(instance.questionnaire_title.toUpperCase())) {
        return false;
      }
    }

    if ($ctrl.dateRegExp) {
      if (!$ctrl.dateRegExp.test($ctrl.parseDate(instance))) {
        return false;
      }
    }

    if ($ctrl.senderRegExp) {
      if (!$ctrl.senderRegExp.test($ctrl.parseSender(instance).toUpperCase())) {
        return false;
      }
    }

    return !(!$ctrl.showCompleted && !instance.is_open);
  };
};

export default {
  controller: QuestionnaireInstancesList,
  templateUrl: 'components/questionnaireInstancesList.html',
  bindings: {
    questionnaireInstances: '<'
  }
};